import { Link } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import Context from "../functions/context";
import ActiveLink from "./ActiveLink";
import { SET_LOCALE, TOGGLE_CALCULATOR_MODAL } from "../functions/constants";
import { useTranslation } from "react-i18next";
import SearchForm from "./SearchForm";
import { useOnClickOutside } from "../functions/useOnClickOutside";
import HeaderMessageLogin from "./HeaderMessageLogin";

const locales = { en: "Eng", ru: "Rus", uz: "Uzb" };

export default function MenuContent() {
  const [activeRoute, setActiveRoute] = useState("");
  const inputRef = useRef();
  const [openLangSelect, toggleLangSelect] = useState(false);
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);
  const ref = useRef();
  const shops = JSON.parse(localStorage.getItem("shops"));
  const {
    mainState: { customer },
    mainDispatch,
  } = useContext(Context);
  const [searchActive, setSearchActive] = useState(false);
  const { t, i18n } = useTranslation();
  const lang = localStorage.getItem("locale");
  const pathname = window.location.pathname;
  useOnClickOutside(ref, () => toggleLangSelect(false));
  useEffect(() => {
    if (!searchActive) {
      document.getElementById("header-nav").classList.remove("d-none");
    } else document.getElementById("header-nav").classList.add("d-none");
  }, [searchActive]);

  function searchButton(e) {
    setSearchActive(!searchActive);
    e.preventDefault();
  }

  function pageRender(route) {
    setActiveRoute(route);
    setToggleMobileMenu(false);
  }

  return (
    <section id="navbar" className="container-fluid">
      <nav className="navbar navbar-expand-lg navbar-light container">
        <Link className="navbar-brand" to="/">
          <img src="/images/logo.svg" alt="logo" />
        </Link>
        <button
          className={`me-auto navbar-toggler ${
            !toggleMobileMenu ? "collapsed" : ""
          }`}
          onClick={() => setToggleMobileMenu(!toggleMobileMenu)}
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className={`collapse navbar-collapse ${
            toggleMobileMenu ? "show" : ""
          }`}
          id="navbarToggleButton"
        >
          <div className="mobile-closer d-none">
            <button
              className="closer btn"
              onClick={() => setToggleMobileMenu(!toggleMobileMenu)}
            >
              <img src="/images/cancel.svg" alt="closer" />
            </button>
          </div>
          <ul className="navbar-nav me-auto" id="header-nav">
            <li className="nav-item">
              <ActiveLink
                className={`nav-link ${
                  activeRoute === "plans" ? "active" : ""
                }`}
                onClick={() => pageRender("plans")}
                to="/plans"
              >
                {t("menu.Tariffs")}
              </ActiveLink>
            </li>
            <li className="nav-item">
              <ActiveLink
                className={`nav-link ${
                  activeRoute === "services" ? "active" : ""
                }`}
                onClick={() => pageRender("services")}
                to="/services"
              >
                {t("menu.Services")}
              </ActiveLink>
            </li>
            <li className="nav-item">
              <ActiveLink
                className={`nav-link ${
                  activeRoute === "shops" ? "active" : ""
                }`}
                onClick={() => pageRender("shops")}
                to="/shops"
              >
                {t("menu.Shops")}
              </ActiveLink>
            </li>
            <li className="nav-item">
              <ActiveLink
                className={`nav-link ${
                  activeRoute === "about" ? "active" : ""
                }`}
                onClick={() => pageRender("about")}
                to="/about"
              >
                {t("menu.About")}
              </ActiveLink>
            </li>
            <li className="nav-item">
              <ActiveLink
                className={`nav-link ${
                  activeRoute === "contact" ? "active" : ""
                }`}
                onClick={() => pageRender("contact")}
                to="/contact"
              >
                {t("menu.Contacts")}
              </ActiveLink>
            </li>
            <li className="nav-item">
              <ActiveLink
                className={`nav-link ${activeRoute === "help" ? "active" : ""}`}
                onClick={() => pageRender("help")}
                to="/help"
              >
                {t("menu.Help")}
              </ActiveLink>
            </li>
            <li className="nav-item">
              <ActiveLink
                className={`nav-link ${
                  activeRoute === "franchize" ? "active" : ""
                }`}
                onClick={() => pageRender("franchize")}
                to="/franchize"
              >
                {t("menu.Franchising")}
              </ActiveLink>
            </li>
            <li className="nav-item">
              <ActiveLink
                className={`nav-link ${
                  activeRoute === "subscription" ? "active" : ""
                }`}
                onClick={() => pageRender("subscription")}
                to="/subscription"
              >
                {t("menu.Subscription")}{" "}
                {customer?.active_subscription && (
                  <img src="/images/icons/crown.svg" alt="crown" />
                )}
              </ActiveLink>
            </li>
            {/* {customer && (
              <li className="nav-item only-mobile">
                <ActiveLink
                  to="/cabinet/profile"
                  className={`nav-link ${
                    activeRoute === "profile" ? "active" : ""
                  }`}
                  onClick={() => pageRender("profile")}
                >
                  {t("menu.profile")}
                </ActiveLink>
              </li>
            )} */}
            {customer && (
              <HeaderMessageLogin pageRender={pageRender} activeRoute />
            )}
            {!customer && (
              <li className="nav-item only-mobile">
                <ActiveLink
                  to="/login"
                  onClick={() => pageRender("login")}
                  className={`nav-link ${
                    activeRoute === "login" ? "active" : ""
                  }`}
                >
                  {t("menu.Login")}
                </ActiveLink>
              </li>
            )}
            {!customer && (
              <li className="nav-item only-mobile">
                <ActiveLink
                  to="/registration"
                  className={`nav-link ${
                    activeRoute === "registration" ? "active" : ""
                  }`}
                  onClick={() => pageRender("registration")}
                >
                  {t("menu.Registration")}
                </ActiveLink>
              </li>
            )}
          </ul>
          <ul className="navbar-nav navbar-right">
            {pathname !== "/shops" ? (
              <>
                <SearchForm
                  searchActive={searchActive}
                  setActive={setSearchActive}
                  inputRef={inputRef}
                  shops={shops}
                />
                <li className="nav-item">
                  <a
                    href="#"
                    onClick={(e) => searchButton(e)}
                    className="nav-link"
                    style={{ padding: 0 }}
                  >
                    {searchActive ? (
                      <i className="ri-close-line" style={{ fontSize: 21 }} />
                    ) : (
                      <i className="ri-search-line" style={{ fontSize: 21 }} />
                    )}
                  </a>
                </li>
              </>
            ) : null}

            {/* {(isHomeUzPage || isHomeEnPage) && */}
            <li className="nav-item">
              <a
                onClick={() => mainDispatch({ type: TOGGLE_CALCULATOR_MODAL })}
                className="nav-link"
                style={{ padding: 0, marginRight: 12 }}
              >
                <i className="ri-calculator-line" style={{ fontSize: 21 }} />
              </a>
            </li>
            {/* } */}

            <li className="btn-lang">
              <div className={`lang-menu ${openLangSelect ? "act" : ""}`}>
                <div
                  className={`selected-lang justify-content-between selected-lang--${
                    lang || "en"
                  }`}
                  onClick={() => toggleLangSelect(!openLangSelect)}
                  id="lang-menu-id"
                >
                  <img
                    style={{ marginRight: "8px" }}
                    src={`/images/new/${lang || "en"}-flag.svg`}
                    width="30"
                  />
                  <i
                    className="ri-arrow-down-s-line"
                    style={{ fontSize: 14 }}
                  />
                </div>
                <ul ref={ref}>
                  {Object.keys(locales).map(
                    (locale) =>
                      locale !== lang && (
                        <li key={locale}>
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              localStorage.setItem("locale", locale);
                              i18n.changeLanguage(locale).then((r) => {
                                toggleLangSelect(false);
                              });
                              mainDispatch({
                                type: SET_LOCALE,
                                payload: locale,
                              });
                            }}
                            className={locale}
                            href="#"
                          />
                        </li>
                      )
                  )}
                </ul>
              </div>
              <div
                className="modal fade"
                id="langModal"
                tabIndex="-1"
                aria-labelledby="parcelsModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                  <a href="#" className="select-lang select-lang-bottom">
                    <img
                      src={`/images/new/${lang || "en"}-flag.svg`}
                      width="30"
                    />
                  </a>
                </div>
              </div>
            </li>
            {customer && <HeaderMessageLogin />}
            {!customer && (
              <li className="nav-item">
                <Link to="/login" className="nav-link btn-login mx-0">
                  {t("menu.Login")}
                </Link>
              </li>
            )}
            {!customer && (
              <li className="nav-item">
                <Link
                  to="/registration"
                  className="nav-link btn-register register-btn-header"
                >
                  {t("menu.Registration")}
                </Link>
              </li>
            )}
          </ul>
        </div>
      </nav>
    </section>
  );
}
